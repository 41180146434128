@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./member.scss";
@import "./datepicker.scss";

* {
  @apply font-IBM;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.heading {
  font-size: 18px;
  font-weight: 700;
}

.body-b {
  font-size: 16px;
  font-weight: 700;
}

.label-b {
  font-size: 14px;
  font-weight: 700;
}

.label-m {
  font-size: 14px;
  font-weight: 500;
}

.caption-b {
  font-size: 12px;
  font-weight: 700;
}

.caption-m {
  font-size: 12px;
  font-weight: 500;
}

.remark {
  font-size: 10px;
  font-weight: 500;
}

.remark-b {
  font-size: 10px;
  font-weight: 700;
}
